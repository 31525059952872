import { createAsyncThunk } from "@reduxjs/toolkit";
import ProfileApi from "./API";
import User from "redux/user";
import { toast } from "react-toastify";

export const doGetProfile = createAsyncThunk<any, any, any>(
  "profile/fetch",
  async (_, { rejectWithValue }) => {
    try {
      const response = await ProfileApi.getProfile();
      console.log({response} , "userProfile")
      
      return {
        data: response.data.data,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const doGetQuizzes = createAsyncThunk<any, any, any>(
  "profile/queizzes/fetch",
  async (_, { rejectWithValue }) => {
    try {
      const response = await ProfileApi.getQuizzesAttemps();
      return {
        data: response.data.data,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const doGetProfileDashboard = createAsyncThunk<any, any, any>(
  "profile/dashboard",
  async (_, { rejectWithValue }) => {
    try {
      const response = await ProfileApi.getProfileDashboard();
      return {
        data: response.data.data,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const doGetEnrolledCourses = createAsyncThunk<any, any, any>(
  "profile/enrolled_courses",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ProfileApi.getEnrolledCourses(data);
      return {
        data: response.data,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const doEditProfile = createAsyncThunk<any, any, any>(
  "my/settings",
  async (data, { rejectWithValue  , dispatch}) => {
    try {
      const response = await ProfileApi.editProfile(data);
      dispatch(User.thunks.doRefresh({}))
      return {
        data: response.data,
      };
    } catch (error:any) {
      error?.response?.data?.message&&   toast.error( error?.response?.data?.message)
      return rejectWithValue(error);
    }
  }
);

export const doUploadProfileImage = createAsyncThunk<any, any, any>(
  "profile/upload_profile_image",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ProfileApi.uploadPhoto(data);
      return {
        data: response.data,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const doGetEnrolledCloudRooms = createAsyncThunk<any, any, any>(
  "profile/get_enrolled_cloud_rooms",
  async (_, { rejectWithValue }) => {
    try {
      const response = await ProfileApi.getEnrolledCloudRooms();
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const doGetFav = createAsyncThunk<any, any, any>(
  "profile/get_Favourites",
  async (_, { rejectWithValue }) => {
    try {
      const response = await ProfileApi.getFav();
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const doAddFav = createAsyncThunk<any, any, any>(
  "profile/favourits/add",
  async (id, { rejectWithValue }) => {
    try {
      const response = await ProfileApi.addFav(id);
      return {
        data: response.data,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const doRemoveFav = createAsyncThunk<any, any, any>(
  "profile/favourites/remove",
  async (id, { rejectWithValue }) => {
    try {
      const response = await ProfileApi.removeFav(id);
      return {
        data: response.data,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const thunks = {
  doGetProfile,
  doGetProfileDashboard,
  doGetEnrolledCourses,
  doGetQuizzes,
  doUploadProfileImage,
  doEditProfile,
  doGetEnrolledCloudRooms,
  doGetFav,
  doRemoveFav,
  doAddFav,
};

export default thunks;
