import axios from "api/services/axios";

const getProfile = () => axios.get(`/my/settings`);
const getQuizzesAttemps = () => axios.get(`/my/quiz-attempts`);
const getProfileDashboard = () => axios.get("/my/profile/dashboard");
const getEnrolledCourses = ({
  search,
  categories,
  levels,
  classes,
  price_min,
  price_max,
  page,
}: any) => {
  let priceParams;

  if (price_min && price_max) {
    priceParams = `&price_min=${price_min}&price_max=${price_max}`;
  }
  return axios.get(
    `/my/courses/enrolled?q=${search || ""}${
      categories && categories.length > 0
        ? categories
            .map((item: any) => `&category[]=${item}`)
            .toString()
            .replace(/,/g, "")
        : ""
    }${
      levels && levels.length > 0
        ? levels
            .map((item: any) => `&level[]=${item}`)
            .toString()
            .replace(/,/g, "")
        : ""
    }${
      classes && classes.length > 0
        ? classes
            .map((item: any) => `&class[]=${item}`)
            .toString()
            .replace(/,/g, "")
        : ""
    }${priceParams || ""}&page=${page || 1}`
  );
};
const getEnrolledCloudRooms = () => axios.get("/my/cloud-rooms/enrolled");
const editProfile = (data: any) => axios.patch("/my/settings", data);
const uploadPhoto = (data: any) =>
  axios.post("/my/settings/photo", { file: data });
const getFav = () => axios.get("/my/wishlist");
const addFav = (id: any) => axios.post(`/my/wishlist/${id}`);
const removeFav = (id: any) => axios.delete(`/my/wishlist/${id}`);
const ProfileApi = {
  getProfile,
  getQuizzesAttemps,
  getProfileDashboard,
  getEnrolledCourses,
  editProfile,
  uploadPhoto,
  getEnrolledCloudRooms,
  getFav,
  addFav,
  removeFav,
};

export default ProfileApi;
