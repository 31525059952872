import axios from 'axios';
// import {store} from '../../redux/store';
import Cookies  from 'js-cookie';

const baseURL =  "https://staging-api.ibnabbas.com/api"//process.env.REACT_APP_BASE_DEV_URL;
// const baseURL =  "https://services.injaah.com/api"//process.env.REACT_APP_BASE_DEV_URL;

const Axios = axios.create({baseURL});
Axios.defaults.baseURL = baseURL;

Axios.interceptors.request.use(async (req) => {
  const token =  Cookies.get("UT");
//   console.log({token})

  //don't check the token in login or register
  if ((req.url === 'login') || (req.url === 'register')) {
    return req;
  }

  let updatedParams = {...req?.params};
  req.params = updatedParams;
  
 

  req.headers.Authorization = `Bearer ${token}`;
//   console.log({req})

  ///to handle form data
  if (req.data instanceof FormData) {
    req.headers['Content-Type'] = 'multipart/form-data';
  }
  return req;
});


Axios.interceptors.response.use(
  res => { console.log({res});return res;},
  err => {
    // console.log({err}, '======> Axios Error' );
    if (err?.response?.status === 401 || err?.response?.status === 403) {
    
    }else{
      
    }

    return Promise.reject(err);
  },
);

export default Axios;
