import { createAsyncThunk } from "@reduxjs/toolkit";
import constantsApi from "./API";

export const doGetClasses = createAsyncThunk<any, any, any>(
  "constant/classes",
  async (_, { rejectWithValue }) => {
    try {
      const response = await constantsApi.getClasses();
      return {
        data: response?.data,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const doGetLevels = createAsyncThunk<any, any, any>(
  "constant/levels",
  async (_, { rejectWithValue }) => {
    try {
      const response = await constantsApi.getLevels();
      return {
        data: response?.data,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const thunks = {
  doGetClasses,
  doGetLevels,
};

export default thunks;
