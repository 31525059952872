import { EntityKeys } from "../schema";
import { createSlice } from "@reduxjs/toolkit";
import thunks from "./thunks";
import { RootState, store } from "../store";
import { toast } from "react-toastify";
import { TUser } from "./model";
import { CoursesInitialValues } from "redux/courses";
import Cookies from "js-cookie";

type TInitialValues = {
  isVerified: boolean;
  currentUser?: TUser;
};

const UD = Cookies.get('UD')
const initialValues: TInitialValues = {
  currentUser: UD ? JSON?.parse(UD) :undefined,
  isVerified: false,
};

const slice = createSlice({
  name: EntityKeys.USERS,
  initialState: initialValues,
  reducers: {
    logoutUser : (state)=>{
      state.currentUser = undefined
      state.isVerified = false
    }
  },
  extraReducers: (builder) => {
    builder.addCase(thunks.doLogIn.fulfilled, (state, action) => {
      console.log(' action.payload.data.data.user' ,  action.payload.data.data.user)
      state.currentUser = action.payload.data.data.user;
    });
    builder.addCase(thunks.doLogIn.rejected, (state, action: any) => {
      toast.error("The Email or Password is Incorrect");
    });
    builder.addCase(thunks.doRegister.fulfilled, (state, action) => {
      state.currentUser = action.payload.data.data.user;
    });
    builder.addCase(thunks.doRegister.rejected, (state, action: any) => {
      toast.error(action?.payload?.response?.data?.errors?.[0]?.message?.email);
    });
    builder.addCase(thunks.doForgotPassword.fulfilled, (state, action) => {
      console.log(action);
    });
    builder.addCase(
      thunks.doForgotPassword.rejected,
      (state, action: any) => {}
    );
    builder.addCase(thunks.doLogout.pending, (state, action: any) => {
      state.currentUser = undefined;
      state.isVerified = initialValues.isVerified;
    });
    builder.addCase(thunks.doLogout.rejected, (state, action: any) => {
    
      toast.error(action?.payload?.response?.data?.message);
    });
    builder.addCase(thunks.doLogout.fulfilled, (state, action) => {
      state.currentUser = undefined;
      state.isVerified = initialValues.isVerified;
    });
    builder.addCase(thunks.doRefresh.fulfilled, (state, action) => {
      state.currentUser = action.payload.data.data.user;
    });
    builder.addCase(thunks.doRefresh.rejected, (state, action) => {
      state.currentUser = initialValues.currentUser;
      state.isVerified = initialValues.isVerified;
    });
    builder.addCase(thunks.doResetPassword.rejected, (state, action: any) => {
      toast.error(action?.payload?.response?.data?.msg);
    });
    builder.addCase(thunks.doResetPassword.fulfilled, (state, action) => {});
    builder.addCase(thunks.doVerifyEmail.rejected, (state, action) => {});
    builder.addCase(thunks.doVerifyEmail.fulfilled, (state, action) => {});
  },
});

const User = {
  thunks,
  slice,
};

export const {logoutUser} =  slice.actions
export const selectCurrentUser = (state: RootState) => state.users.currentUser;
export const selectIsVerified = (state: RootState) => state.users.isVerified;

export default User;
