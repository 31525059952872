import { EntityKeys } from "../schema";
import { createSlice } from "@reduxjs/toolkit";
import thunks from "./thunks";
import { RootState } from "../store";
import { toast } from "react-toastify";
import User from "redux/user";
import { TFav, TProfile, TQuizzesAttemps } from "./model";
import { TCourses } from "redux/courses/model";
import { TCloudRooms } from "redux/cloudRooms/model";
import { stat } from "fs";
import Cookies from "js-cookie";

type TInitialValues = {
  userProfile?: TProfile;
  dashboardData?: {
    certificates_count?: number;
    enrolled_courses_count?: number;
    completed_courses_count?: number;
    reviews_count?: number;
    in_progress_courses?: TCourses[];
  };
  enrolledCourses?: TCourses[];
  enrolledCloudRooms?: TCloudRooms[];
  enrolledCloudRoomsDetails: {
    enrolledCloudRooms: TCloudRooms[];
    current_page: number;
  };
  enrolledCoursesDetails: {
    current_page?: number;
    from?: number;
    last_page?: number;
    path?: string;
    per_page?: number;
    to?: number;
    total?: number;
  };
  fav?: TFav[] | undefined;
  quizzesAttemps?: TQuizzesAttemps[];
  loading?:boolean
};

const UD = Cookies.get('UD')
const initialValues: TInitialValues = {
  userProfile: UD ? JSON?.parse(UD) :undefined,
  dashboardData: {
    certificates_count: 0,
    enrolled_courses_count: 0,
    completed_courses_count: 0,
    reviews_count: 0,
    in_progress_courses: [],
  },
  enrolledCourses: [],
  enrolledCloudRooms: [],
  enrolledCloudRoomsDetails: {
    enrolledCloudRooms: [],
    current_page: 1,
  },
  enrolledCoursesDetails: {},
  fav: [],
  quizzesAttemps: [],
  loading: false,
};

const slice = createSlice({
  name: "profiles",
  initialState: initialValues,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(thunks.doGetProfile.pending, (state) => {
        state.loading = true; // Set loading to true when request is pending
    })
    builder.addCase(thunks.doGetProfile.fulfilled, (state, action) => {
      state.userProfile = action.payload?.data;
      state.loading = false;
    });
    builder.addCase(thunks.doGetProfile.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(User.thunks.doRefresh.rejected, (state, action) => {
      state.userProfile = initialValues.userProfile;
    });
    builder.addCase(thunks.doGetProfileDashboard.fulfilled, (state, action) => {
      state.dashboardData = action.payload?.data;
    });
    builder.addCase(thunks.doGetProfileDashboard.rejected, (state, action) => {
      console.log(action);
    });
    builder.addCase(thunks.doGetEnrolledCourses.fulfilled, (state, action) => {
      if (action.payload.data.message === "No data Found") {
        // toast.error("No courses were found");
        state.enrolledCourses = [];
        state.enrolledCoursesDetails.last_page = 0;
        return;
      }
      state.enrolledCourses = action.payload?.data?.data["courses "]?.data;
      state.enrolledCoursesDetails = action.payload?.data?.data?.["courses "]?.meta;
    });
    builder.addCase(thunks.doGetEnrolledCourses.rejected, (state, action) => {
      console.log(action);
    });
    builder.addCase(thunks.doGetQuizzes.fulfilled, (state, action) => {
      state.quizzesAttemps = action.payload.data;
    });
    builder.addCase(thunks.doGetQuizzes.rejected, (state, action) => {
      console.log(action);
    });
    builder.addCase(thunks.doEditProfile.rejected, (state, action) => {
      console.log(action);
    });
    builder.addCase(thunks.doEditProfile.fulfilled, (state, action:any) => {
      state.userProfile =  action?.payload?.data?.data       
      toast.success("Profile Updated successfully");
    });
    builder.addCase(thunks.doUploadProfileImage.rejected, (state, action) => {
      console.log(action);
    });
    builder.addCase(thunks.doUploadProfileImage.fulfilled, (state, action) => {
      console.log(action);
    });
    builder.addCase(
      thunks.doGetEnrolledCloudRooms.fulfilled,
      (state, action) => {
        state.enrolledCloudRooms = action.payload?.data?.cloud_rooms.data;
      }
    );
    builder.addCase(
      thunks.doGetEnrolledCloudRooms.rejected,
      (state, action) => {
        console.log(action);
      }
    );
    builder.addCase(thunks.doGetFav.fulfilled, (state, action) => {
      state.fav = action.payload.data;
    });
    builder.addCase(thunks.doGetFav.rejected, (state, action) => {
    //   toast.error("No favourites were found");
      console.log(action);
    });
    builder.addCase(thunks.doAddFav.fulfilled, (state, action) => {
      console.log(action);
    });
    builder.addCase(thunks.doAddFav.rejected, (state, action) => {
      console.log(action);
    });
    builder.addCase(thunks.doRemoveFav.fulfilled, (state, action) => {
      console.log(action);
    });
    builder.addCase(thunks.doRemoveFav.rejected, (state, action) => {
      console.log(action);
    });
  },
});

const Profile = {
  thunks,
  slice,
};

export const selectUserProfile = (state: RootState) =>
  state.profiles.userProfile;
export const selectDashboardData = (state: RootState) =>
  state.profiles.dashboardData;
export const selectEnrolledCourses = (state: RootState) =>
  state.profiles.enrolledCourses;
export const selectCLoudRoomsProfile = (state: RootState) =>
  state.profiles.enrolledCloudRoomsDetails;
export const selectEnrolledCloudRooms = (state: RootState) =>
  state.profiles.enrolledCloudRooms;
export const selectEnrolledCoursesDetails = (state: RootState) =>
  state.profiles.enrolledCoursesDetails;
export const selectFav = (state: RootState) => state.profiles.fav;
export const selectQuizzesAttemps = (state: RootState) =>
  state.profiles.quizzesAttemps;
export const selectProfileLoading = (state: RootState) =>
  state.profiles.loading; // Export loading state
export default Profile;
