import { PayloadAction, createSlice } from "@reduxjs/toolkit";


type InitialValues = {
  showCreateCodeModal:boolean
};

export const initialValues: InitialValues = {
  showCreateCodeModal:false
};

const codesSlice = createSlice({
  name: "codes",
  initialState: initialValues,
  reducers: {
    toggleShowCodeModal:(state, action:PayloadAction<{ value:boolean }>) => {
      state.showCreateCodeModal = action.payload?.value
    },
  },
  
});


export const {toggleShowCodeModal } = codesSlice.actions

export default codesSlice.reducer